.restricted-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link{
    cursor: pointer;
    color: rgb(33, 33, 230);
    text-decoration: underline;
    font-weight: 500;
}