

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body{
    font-family: 'Roboto',sans-serif;
}
.home-txt{
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    padding-bottom: 2px;
}

.home-padding{
    padding-top: 10px;
}

.sidebar{
    display: flex;
    justify-content: center;
}

.topbar{
    height: 60px;
    border-bottom: 0.5px solid rbg(231,228,228);
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #555;
    background-color: #fff;
    position: relative;
}

.toast{
    margin-top: 50px;
    z-index: 9999;
}
.choosebtn{
   max-width: 40px;
   /* max-height: 18px; */
   /* height: 20px; */
}


.content{
    position: absolute;
    margin: 70px 210px;
    width: 83%;
    z-index: 0;
    
}

.wrapper{
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item{
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;
}
.items{
    display: flex;
    align-items: center;
}

.counter{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    font-size: 10px;
}

.home-content{
    margin-top: 18px;
    padding: 20px 18px;
    /* background-color: aqua; */
}

a{
    text-decoration: none;
    color: rgba(46, 43, 43,.85);
}