@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.PhoneInput input{
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid rgba(25, 25, 25, 0.3);
}

.PhoneInput input::-webkit-input-placeholder{
  font-size: 1rem;
}

.PhoneInput input::placeholder{
  font-size: 1rem;
}

.PhoneInput input:focus {
      border-color: #1b9bfd;
      border: 1px solid rgba(181, 175, 175, 0.3);
      box-shadow: 0 0 0 0.2rem rgba(11, 125, 248, 0.25);
  
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



body{
    font-family: 'Roboto',sans-serif;
}
.home-txt{
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    padding-bottom: 2px;
}

.home-padding{
    padding-top: 10px;
}

.sidebar{
    display: flex;
    justify-content: center;
}

.topbar{
    height: 60px;
    border-bottom: 0.5px solid rbg(231,228,228);
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #555;
    background-color: #fff;
    position: relative;
}

.toast{
    margin-top: 50px;
    z-index: 9999;
}
.choosebtn{
   max-width: 40px;
   /* max-height: 18px; */
   /* height: 20px; */
}


.content{
    position: absolute;
    margin: 70px 210px;
    width: 83%;
    z-index: 0;
    
}

.wrapper{
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item{
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;
}
.items{
    display: flex;
    align-items: center;
}

.counter{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    font-size: 10px;
}

.home-content{
    margin-top: 18px;
    padding: 20px 18px;
    /* background-color: aqua; */
}

a{
    text-decoration: none;
    color: rgba(46, 43, 43,.85);
}

/* DataTableDemo.css */

.datatable-crud-demo{
    margin: 30px;
    width: 98%;
}


.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .table-header {
        align-items: flex-start;
    }
}
.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .p-toolbar {
        flex-wrap: wrap;
    }
    .datatable-crud-demo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatable-crud-demo .table-header {
        flex-direction: column;
    }
    .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
        width: 100%;
    }
}
                 
.restricted-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link{
    cursor: pointer;
    color: rgb(33, 33, 230);
    text-decoration: underline;
    font-weight: 500;
}
