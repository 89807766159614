body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.PhoneInput input{
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid rgba(25, 25, 25, 0.3);
}

.PhoneInput input::placeholder{
  font-size: 1rem;
}

.PhoneInput input:focus {
      border-color: #1b9bfd;
      border: 1px solid rgba(181, 175, 175, 0.3);
      box-shadow: 0 0 0 0.2rem rgba(11, 125, 248, 0.25);
  
}